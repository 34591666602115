.header {
    background-color: var(--color-darkblue);
    color: white;
    display: flex;
    justify-content: center;
    position: relative;
}

.headerContainer {
    width: 100%;
    max-width: 1024px;
    margin: 20px 0 100px 0;
}

.headerContainer.listMode {
    margin: 20px 0 0 0;
}

.headerList {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
}

.headerListItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerListItem.active {
    border: 1px solid white;
    padding: 10px;
    border-radius: 20px;
}

.headerDesc {
    margin: 20px 0;
}

.headerBtn {
    background-color: var(--color-blue);
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.headerSearch {
    height: 30px;
    background-color: white;
    border: 3px solid var(--color-yellow);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
    border-radius: 5px;
    position: absolute;
    bottom: -28px;
    width: 100%;
    max-width: 1024px;
}

.headerIcon {
    color: lightgray;
}

.headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerSearchInput {
    border: none;
    outline: none;
}

.headerSearchText {
    color: lightgray;
    cursor: pointer;
}

.date {
    position: absolute;
    top: 50px;
    z-index: 2;
}

.options {
    position: absolute;
    top: 50px;
    background-color: white;
    color: gray;
    border: 1px solid black;
    border-radius: 5px;
    z-index: 2;
}

.optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    color: var(--color-darkblue);
    border: 1px solid var(--color-darkblue);
    cursor: pointer;
    background-color: white;
}

.optionCounterButton:disabled {
    cursor: not-allowed;
}