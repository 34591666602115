.featured {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
}

.featuredImg {
    width: 100%;
    object-fit: cover;
}

.featuredItem {
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
}

.featuredTitles {
    position: absolute;
    bottom: 20px;
    left: 20px;
}