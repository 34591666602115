.navbar {
    height: 50px;
    background-color: var(--color-darkblue);
    display: flex;
    justify-content: center;
}

.navContainer {
    width: 100%;
    max-width: 1024px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: 500;
}

.navButton {
    margin-left: 20px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--color-darkblue);
}