.reserve {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rContainer {
    background-color: white;
    padding: 30px;
    position: relative;
}

.rClose {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.rItem {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px;
}

.rItemInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rButton {
    border: none;
    padding: 10px 20px;
    background-color: var(--color-blue);
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
}