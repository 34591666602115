.searchItem {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.siImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.siDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}

.siTitle {
    font-size: 20px;
    color: var(--color-darkblue);
}

.siDistance {
    font-size: 12px
}

.siTaxiOp {
    font-size: 12px;
    background-color: green;
    color: white;
    width: max-content;
    padding: 3px;
    border-radius: 5px;
}

.siSubtitle {
    font-size: 12px;
    font-weight: bold;
}

.siFeatures {
    font-size: 12px;
}

.siCancelOp {
    font-size: 12px;
    color: green;
    font-weight: bold;
}

.siCancelOpSubtitle {
    font-size: 12px;
    color: green;
}

.siDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.siRating {
    display: flex;
    justify-content: space-between;
}

.siRating > button {
    background-color: var(--color-darkblue);
    color: white;
    border: none;
    padding: 5px;
    font-weight: bold;
}

.siDetailTexts {
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.siPrice {
    font-size: 24px;
}

.siCheckButton {
    background-color: var(--color-blue);
    color: white;
    border: none;
    padding: 10px 5px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}